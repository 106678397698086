import React from "react";
import { Layout, Seo } from "components/common";
import { Intro, Skills, Contact, Projects } from "components/landing";
import { Grid } from "../components/landing/Projects/styles"
import Folio from '../components/common/folioLayout/folio'
import "./style.css"
import { StaticImage } from "gatsby-plugin-image"

export const Aerial = () => {
    return (
        <>
            <title> Aerial </title>
            <div class="gre">
                <Layout>
                    <Seo />
                    <Folio pageTitle="Aerial Pixls">
                        {/* <Grid> */}
                        <div class="row">
                            <div class="column">
                                <StaticImage src='../images/aerial/1.JPG' layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/aerial/2.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/aerial/3.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/aerial/10.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                            <div class="column">
                                <StaticImage src="../images/aerial/4.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/aerial/5.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/aerial/11.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/aerial/8.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                            <div class="column">
                                <StaticImage src='../images/aerial/6.JPG' layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/aerial/7.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                            <div class="column">
                                <StaticImage src="../images/aerial/9.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                        </div>
                        {/* </Grid> */}
                    </Folio>

                </Layout>
            </div>
        </>
    );
};


export default Aerial;